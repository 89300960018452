import React from 'react'

export default function CrtInfo() {
    return (
        <section id="back-07" className="about about-2 about-4 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="row heading heading-2">
                <div className="col-sm-12 col-md-8 col-sm-12">
                    <small className="small-title">Certificaciones</small>
                  <h2 className="heading__title">Nos mantenemos en constate capacitación</h2>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <p className="heading__desc mb-30">Estamos comprometidos en mantener nuestros estándares de seguridad al más alto nivel.</p>
                  <p className="heading-desc">Para prevenir, asegurar y reconocer posibles amenazas en nuestra cadena de suministro, con el propósito de garantizar el embarque de exportación, importación y nacionales.</p>
                  <p className="heading-desc">Libres de productos ilícitos, tráfico de armas, tráfico de personas y contrabando que puedan representar un riesgo para nuestra empresa, así como para nuestros clientes y para la sociedad en general.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
