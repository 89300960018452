import React from 'react'

export default function Careers() {
    return (
        <section id="careers" class="careers bg-gray">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div class="heading text-center mb-50">
                            <span class="heading__subtitle small-title">Trabaja con nosotros</span>
                            <h2 class="heading__title">Vacantes abiertas</h2>
                            <p>Las marcas más importante del mundo no pueden existir sin personas talentosas como tú. En LOGÍSTICA LEMA DE QUERÉTARO S DE RL DE CV,
                                creemos que la calidad, la agilidad, el compromiso y la responsabilidad crean una cultura de innovación y crecimiento. </p>
                            <div class="divider__line divider__center"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="row heading mb-40">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h2 class="heading__title">Operación</h2>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="carousel owl-carousel owl-loaded owl-drag" data-slide="3" data-slide-md="2" data-slide-sm="1" data-autoplay="true" data-nav="false" data-dots="false" data-space="30" data-loop="true" data-speed="800">
                            <div class="owl-stage-outer">
                                <div class="owl-stage">
                                    <div class="owl-item cloned" >
                                        <div className="row">
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class=" job-item">
                                                    <div class="job__meta"><span class="job__type">Tiempo completo</span><span class="job__location">Querétaro, Qro.</span></div>
                                                    <h4 class="job__title">Tráfico</h4>
                                                    <p class="job__desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit labore porro quas aspernatur consectetur ducimus debitis! Ipsam iure inventore dicta veritatis blanditiis ad, delectus excepturi mollitia! Dicta fugiat dolorem nulla.</p>
                                                    <a href="#" class="btn btn__secondary btn__link btn__underlined">Postularme</a>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class=" job-item">
                                                    <div class="job__meta"><span class="job__type">Tiempo completo</span><span class="job__location">Querétaro, Qro.</span></div>
                                                    <h4 class="job__title">Operador</h4>
                                                    <p class="job__desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit labore porro quas aspernatur consectetur ducimus debitis! Ipsam iure inventore dicta veritatis blanditiis ad, delectus excepturi mollitia! Dicta fugiat dolorem nulla.</p>
                                                    <a href="#" class="btn btn__secondary btn__link btn__underlined">Postularme</a>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class=" job-item">
                                                    <div class="job__meta"><span class="job__type">Tiempo completo</span><span class="job__location">Querétaro, Qro.</span></div>
                                                    <h4 class="job__title">Mecánico</h4>
                                                    <p class="job__desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit labore porro quas aspernatur consectetur ducimus debitis! Ipsam iure inventore dicta veritatis blanditiis ad, delectus excepturi mollitia! Dicta fugiat dolorem nulla.</p>
                                                    <a href="#" class="btn btn__secondary btn__link btn__underlined">Postularme</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                            <div class="owl-dots disabled"></div>
                            <div class="owl-thumbs"></div>
                        </div>
                    </div>
                </div>
                <div class="row mt-40">
                    <div class="row heading mb-40">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h2 class="heading__title">Administración</h2>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="carousel owl-carousel owl-loaded owl-drag" data-slide="3" data-slide-md="2" data-slide-sm="1" data-autoplay="true" data-nav="false" data-dots="false" data-space="30" data-loop="true" data-speed="800">
                            <div class="owl-stage-outer">
                                <div class="owl-stage">
                                    <div class="owl-item cloned" >
                                        <div className="row">
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class=" job-item">
                                                    <div class="job__meta"><span class="job__type">Tiempo completo</span><span class="job__location">Querétaro, Qro.</span></div>
                                                    <h4 class="job__title">Capital Humano</h4>
                                                    <p class="job__desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit labore porro quas aspernatur consectetur ducimus debitis! Ipsam iure inventore dicta veritatis blanditiis ad, delectus excepturi mollitia! Dicta fugiat dolorem nulla.</p>
                                                    <a href="#" class="btn btn__secondary btn__link btn__underlined">Postularme</a>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class=" job-item">
                                                    <div class="job__meta"><span class="job__type">Tiempo completo</span><span class="job__location">Querétaro, Qro.</span></div>
                                                    <h4 class="job__title">Finanzas</h4>
                                                    <p class="job__desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit labore porro quas aspernatur consectetur ducimus debitis! Ipsam iure inventore dicta veritatis blanditiis ad, delectus excepturi mollitia! Dicta fugiat dolorem nulla.</p>
                                                    <a href="#" class="btn btn__secondary btn__link btn__underlined">Postularme</a>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class=" job-item">
                                                    <div class="job__meta"><span class="job__type">Tiempo completo</span><span class="job__location">Querétaro, Qro.</span></div>
                                                    <h4 class="job__title">Atención al cliente</h4>
                                                    <p class="job__desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit labore porro quas aspernatur consectetur ducimus debitis! Ipsam iure inventore dicta veritatis blanditiis ad, delectus excepturi mollitia! Dicta fugiat dolorem nulla.</p>
                                                    <a href="#" class="btn btn__secondary btn__link btn__underlined">Postularme</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                            <div class="owl-dots disabled"></div>
                            <div class="owl-thumbs"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
