import React from 'react'

export default function AboutInfo() {
    return (
        <section id="back-10" className="about about-2 about-4 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="row heading heading-2">
                <div className="col-sm-12 col-md-12 col-sm-12">
                  <small className="small-title">La esencia de nuestro negocio</small>
                  <h2 className="heading__title">Buscamos el camino hacia un alto desempeño</h2>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <div className="carousel owl-carousel carousel-dots" data-slide="1" data-slide-md="1" data-slide-sm="1"
                    data-autoplay="true" data-nav="false" data-dots="true" data-space="0" data-loop="true"
                    data-speed="700">
                    <div className="fancybox-item">
                      <div className="fancybox__icon">
                        <i className="icon-worldwide"></i>
                      </div>
                      <div className="fancybox__content">
                        <h4 className="fancybox__title">Nuestra Misión</h4>
                        <p className="fancybox__desc">Proveer un servicio especializado, competitivo, apegado a los estándares que rigen el comercio de México con el mundo.</p>
                      </div>
                    </div>
                    <div className="fancybox-item">
                      <div className="fancybox__icon">
                        <i className="icon-transfer"></i>
                      </div>
                      <div className="fancybox__content">
                        <h4 className="fancybox__title">Nuestra Visión</h4>
                        <p className="fancybox__desc">Consolidarnos en una empresa de logística y transportación en una nueva era digital, que a través de la tecnología de punta y con equipos de última generación.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-7">
                  <p className="heading__desc mb-30">Somos una empresa de transporte terrestre, fundada en 1992, con el objetivo de apoyar a la industria y al comercio mexicano con soluciones en transporte y logística.</p>
                  <p>Ponemos a tu servicio nuestra experiencia en el manejo de carga regular para exportación y de importación dentro del territorio nacional y extranjero, ofreciéndole un servicio integrado puerta a puerta en cajas secas y plataformas.</p>
                  <img src="/img/logos/singnture.jpg" alt="singnture" className="signature mb-30" />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-9 col-lg-5">
                <img src="/img/photos/juan.jpg" alt="about img" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    )
}
