import React from 'react'

export default function SliderVideo() {
   return (
      <section class="slider slider-1">
      
            <div class="">
            <video id="video_background" preload="auto" autoplay="true" loop="loop" __idm_id__="597422081">
            <source src="/videos/video2.mp4" type="video/mp4" /> 
        </video>
               <div class="slide-item align-v-h  " >
                  <div class="container">
                     <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-8">
                           <div class="slide__content">
                              <h2 class="slide__title">Las soluciones logísticas confiables y rápidas te ahorran tiempo</h2>
                              <p className="sub-service">Nosotros contamos con ventajas competitivas para tu empresa</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
   </section>
         )
}
