import React from 'react'

export default function BannerServices() {
    return (
        <section id="back-05" className="about about-2 about-4 pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-7">
                        <div className="row heading heading-2">
                            <div className="col-sm-12 col-md-12 col-sm-12">
                                <small className="small-title white banner-text">Estamos para ayudarte</small>
                                <h2 className="heading__title white  banner-desc-text">Queremos que sumes con nosotros</h2>
                                <div class="col-sm-12 col-md-12 col-lg-12 text-left">
                                    <a href="request-quote.html" class="btn btn__primary btn__hover3 btn__lg mt-20  btn-white">Contáctanos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-5">
                        <div className="">
                            <img src="/img/photos/servicios_ilustracion.png" alt="about img" className="img-fluid" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
