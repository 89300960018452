import React from 'react'
import Loader from './../components/Loader'
import SliderJob from './../components/SliderJob'
import Header from './../components/Header'
import Careers from '../components/Careers'
import Footer from './../components/Footer'

export default function Jobs() {
    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            <SliderJob/>
            <Careers/>
            <Footer/>
        </div>
    )
}
