import React from 'react'
import {Switch,Route} from "react-router-dom";
import Home from './screens/Home'
import Service from './screens/Service'
import Trucks from './screens/Trucks'
import Crt from './screens/Crt'
import About from './screens/About'
import Jobs from './screens/Jobs'
import Contact from './screens/Contact'

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/services" component={Service} />
      <Route exact path="/trucks" component={Trucks} />
      <Route exact path="/crt" component={Crt} />
      <Route exact path="/about" component={About} />
      <Route exact path="/jobs" component={Jobs} />
      <Route exact path="/contact" component={Contact} />
    </Switch>
  );
}

export default App;
