import React from 'react'

export default function FormContact() {
    return (
        <section id="contact1" className="contact text-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
              <div className="heading text-center mb-50">
                <small className="small-title">Contáctanos</small>
                <h2 className="heading__title">Solicita tu presupuesto</h2>
                <div className="divider__line divider__theme divider__center mb-0"></div>
                <p className="heading__desc">Las negociaciones en curso garantizan que siempre tengamos el espacio de carga que necesitamos y la capacidad de ofrecerle tarifas competitivas, incluso durante la temporada alta.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
              <form>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="text" className="form-control" placeholder="Nombre" required/></div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="email" className="form-control" placeholder="Email" required/></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="text" className="form-control" placeholder="Teléfono"/></div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="text" className="form-control" placeholder="Empresa"/></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <textarea className="form-control" placeholder="Pedir detalles*" required></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <button type="submit" className="btn btn__primary btn__hover3">Enviar Mensaje</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
}
