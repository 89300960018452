import React from 'react'

export default function Footer() {
    return (
        <footer class="footer-black  mt--130 ">
            {/* <div class="bg-img"><img src="/img/backgrounds/back_03.jpg" alt="background" /></div> */}
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 footer__widget footer__widget-about">
                            <div class="footer__widget-content">
                                <img src="/img/logos/lema_footer.png" alt="logo" class="mb-30 mt-60" />
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 footer__widget footer__widget-nav">
                            <h6 class="footer__widget-title">Navegación</h6>
                            <div class="footer__widget-content">
                                <nav>
                                    <ul class="list-unstyled">
                                        <li><a href="#">Inicio</a></li>
                                        <li><a href="#">Servicios</a></li>
                                        <li><a href="#">Unidades</a></li>
                                        <li><a href="#">Certificaciones</a></li>
                                        <li><a href="#">Nosotros</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 footer__widget footer__widget-nav">
                            <h6 class="footer__widget-title">Servicios</h6>
                            <div class="footer__widget-content">
                                <nav>
                                    <ul class="list-unstyled">
                                        <li><a href="about-us.html">Consolidado</a></li>
                                        <li><a href="leadership-team.html">Dedicado</a></li>
                                        <li><a href="blog.html">Sobredimensionado</a></li>
                                        <li><a href="projects-grid.html">Doble Contenedor</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2 footer__widget footer__widget-newsletter">
                            <h6 class="footer__widget-title">Contacto</h6>
                            <div class="footer__widget-content">
                                <p>Cerro del tambor #30, San Pablo, Querétaro, Qro. 76130</p>
                                <p>trafico1@lematransport.com.mx</p>
                                <p>442 210 11 86</p>
                                <p>442 217 77 67</p>
                                <p>01 800 717 1165</p>
                            </div>
                            <ul class="social__icons list-unstyled">
                                <li><a href="https://www.facebook.com/Lema-Transport-1175244992493544"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                <li><a href="https://api.whatsapp.com/send?phone=+5214424730896"><i class="fa fa-whatsapp"></i></a></li>
                            </ul>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 footer__widget footer__widget-newsletter">
                            
                            <div class="footer__widget-content footer-ctpat">
                                <img src="/img/logos/ctpat.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap justify-content-between">
                            <p class="mb-0 color-white text-center footer-text"> Copyright © 2021 - Todos los derechos reservados a LEMA Transport LLC. Powered by 
                                <a href="https://4thinkdigital.com/"> 4Think</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
