import React from 'react'

export default function Clients() {
    return (
        <section id="clients1" class="clients clients-1 border-top">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="carousel owl-carousel" data-slide="6" data-slide-md="4" data-slide-sm="2" data-autoplay="true"
                data-nav="false" data-dots="false" data-space="20" data-loop="true" data-speed="700">
                <div class="client">
                  <a href="#"><img src="/img/logos/client1.jpg" alt="client" /></a>
                </div>
                <div class="client">
                  <a href="#"><img src="/img/logos/client2.jpg" alt="client" /></a>
                </div>
                <div class="client">
                  <a href="#"><img src="/img/logos/client3.jpg" alt="client" /></a>
                </div>
                <div class="client">
                  <a href="#"><img src="/img/logos/client4.jpg" alt="client" /></a>
                </div>
                <div class="client">
                  <a href="#"><img src="/img/logos/client5.jpg" alt="client" /></a>
                </div>
                <div class="client">
                  <a href="#"><img src="/img/logos/client6.jpg" alt="client" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
