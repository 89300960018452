import React from 'react'

export default function Loader() {
  return (
    // <div className="preloader">
    //   <div className="loading"><span></span><span></span><span></span><span></span></div>
    // </div>
    <div></div>
  )
}
