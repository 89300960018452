import React from 'react'

export default function Lema() {
    return (
        <section id="about4" className="about about-2 about-4 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-5">
              <div className="">
                <img src="/img/photos/home_operador.png" alt="about img" className="img-fluid" />
                
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="row heading heading-2">
                <div className="col-sm-12 col-md-8 col-sm-12">
                    <small className="small-title">Nuestro lema</small>
                  <h2 className="heading__title">La fuerza está en nuestra gente</h2>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-12">
                  <p className="heading__desc mb-30">En Lema Transport entendemos la importancia de crear nuestra propia cantera de operadores.</p>
                  <p className="heading-desc">Todos los días trabajamos por enseñar a las próximas generaciones de operadores profesionales de la 57.</p>
                  <p className="heading-desc">Seguimos persiguiendo esa misma visión trabajando todos los días</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
