import React from 'react'
import { Link } from 'react-router-dom';

export default function Services() {
    return (
        <section id="services" class="services pt-10 bg-gray">
        <div class="container">
          <div class="row heading mb-40">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <small className="small-title">Qué Ofrecemos</small>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
              <h2 class="heading__title">Gestionamos la logística y el transporte para tu empresa.</h2>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 offset-lg-1">
              <p class="heading__desc">Contamos con más de 20 años de experiencia. Nos hemos convertido en expertos en logística y transporte de mercancías por vía terrestre..</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-3 col-lg-3">
              <div class="service-item">
                <div class="service__img">
                  <img src="/img/photos/consolidado.png" alt="service" class="img-fluid" />
                </div>
                <div class="service__overlay">
                  <h4 class="service__title">Consolidado</h4>
                  <p class="service__desc">Ahorrar costos al ser un envío compartido entre varios consignatarios.</p>
                  <a href="#" class="btn btn__white btn__link btn__underlined">Leer más...</a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3">
              <div class="service-item">
                <div class="service__img">
                  <img src="/img/photos/dedicado.png" alt="service" class="img-fluid" />
                </div>
                <div class="service__overlay">
                  <h4 class="service__title">Dedicado</h4>
                  <p class="service__desc">Un camión completo para tu mercancía asegurando su traslado.</p>
                  <a href="#" class="btn btn__white btn__link btn__underlined">Leer más...</a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3">
              <div class="service-item">
                <div class="service__img">
                  <img src="/img/photos/dimensionado.png" alt="service" class="img-fluid"/>
                </div>
                <div class="service__overlay">
                  <h4 class="service__title">Sobre Dimensionado</h4>
                  <p class="service__desc">Si necesita transportar equipo especializado o maquinaria.</p>
                  <a href="#" class="btn btn__white btn__link btn__underlined">Leer más...</a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3">
              <div class="service-item">
                <div class="service__img">
                  <img src="/img/photos/doble.png" alt="service" class="img-fluid"/>
                </div>
                <div class="service__overlay">
                  <h4 class="service__title">Doble Contenedor</h4>
                  <p class="service__desc">El volumen de tu carga no es problema con el servicio para doble remolque.</p>
                  <a href="#" class="btn btn__white btn__link btn__underlined">Leer más...</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
              <Link to="/contact" class="btn btn__primary btn__hover3 btn__lg mt-20" >Solicitar Servicio</Link>
            </div>
          </div>
        </div>
      </section>
    )
}
