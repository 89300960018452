import React from 'react'

export default function ServicesMore() {
    return (
        <section id="" className="fancybox-layout2 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="heading heading-3 mb-50">
                            <span className="heading__subtitle small-title">¿Necesitas algo más?</span>
                            <h2 className="heading__title ">Más que un servicio de transporte somos tu mejor aliado</h2>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="heading heading-3 mb-50">
                            <p className = "service-more-desc">En LEMA Transport nos esforzamos por encontrar la solución de transporte que mejor se adapte a tus necesidades.</p>

                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-import"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Viajes de importación y exportación</h4>
                                <p className="fancybox__desc">Tanto las importaciones como las exportaciones son transacciones comerciales ya sea de bienes o de servicios que se realizan entre distintos estados</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-national"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Servicio de carga nacional</h4>
                                <p className="fancybox__desc">Ofrecemos una amplia gama de servicios de transporte para enviar carga a todo Mexico. Hemos creado acuerdos comerciales con los mejores proveedores. </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-door"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Transporte Door to Door</h4>
                                <p className="fancybox__desc">es de puerta a puerta, tratase de un transporte multimodal en localidades en el exterior, tanto en la importación como en la exportación, buscando beneficios financieros y reducción del tiempo de tránsito.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-rent"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Renta de remolques</h4>
                                <p className="fancybox__desc">Renta, Engancha y Remolca Fácilmente.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-transfer"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Servicio de transbordo</h4>
                                <p className="fancybox__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga vitae optio laudantium neque sunt quo! Laborum aperiam molestias dicta, ratione fugit hic tempore! Expedita, et adipisci laboriosam impedit doloremque provident.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-charge"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Servicio de carga en plataforma</h4>
                                <p className="fancybox__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga vitae optio laudantium neque sunt quo! Laborum aperiam molestias dicta, ratione fugit hic tempore! Expedita, et adipisci laboriosam impedit doloremque provident.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-frontier"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Cruces de frontera</h4>
                                <p className="fancybox__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga vitae optio laudantium neque sunt quo! Laborum aperiam molestias dicta, ratione fugit hic tempore! Expedita, et adipisci laboriosam impedit doloremque provident.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-port"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title">Servicio en puerto de Manzanillo</h4>
                                <p className="fancybox__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga vitae optio laudantium neque sunt quo! Laborum aperiam molestias dicta, ratione fugit hic tempore! Expedita, et adipisci laboriosam impedit doloremque provident.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
