import React from 'react'

export default function Crts() {
  return (
    <section id="clients2" class="clients clients-1 border-top">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class=" center">
              <img src="/img/logos/ctpat.jpg" alt="client" />
              <img src="/img/logos/crm.jpg" alt="client" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
