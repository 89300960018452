import React from 'react'
import { Link } from 'react-router-dom';

export default function Tech() {
    return (
        <section id="fancyboxLayout2" class="fancybox-layout2 pb-60">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-4">
                        <div class="heading heading-3 mb-10">
                            <small className="small-title">Nuestra tecnología</small>
                            <h2 class="heading__title">Tu carga nos importa</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-7">
                        <div class="heading heading-3 mb-50">
                            <p className="descrp-tech">Es por eso que todas nuestras unidades cuentan con sistemas especializados para video-monitorear, controlar y medir activos, vehículos, combustible, carga, actividades y cualquier cosa imaginable en cualquier parte del mundo.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-8 col-lg-8">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="fancybox-item">
                                    <div class="fancybox__icon">
                                        <i class="icon-gps"></i>
                                    </div>
                                    <div class="fancybox__content">
                                        <h4 class="fancybox__title">Geolocalización GPS</h4>
                                        <p class="fancybox__desc">Seguimiento en tiempo real de tu mercancía, nos aseguramos de que tu carga llegue a su destino.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="fancybox-item">
                                    <div class="fancybox__icon">
                                        <i class="icon-lock"></i>
                                    </div>
                                    <div class="fancybox__content">
                                        <h4 class="fancybox__title">Cerraduras inteligentes</h4>
                                        <p class="fancybox__desc">Contamos con sistemas de protección para nuestras unidades y tu mercancía.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="fancybox-item">
                                    <div class="fancybox__icon">
                                        <i class="icon-video"></i>
                                    </div>
                                    <div class="fancybox__content">
                                        <h4 class="fancybox__title">Videograbación</h4>
                                        <p class="fancybox__desc">Grabación a bordo en tiempo real dentro y fuera de nuestras unidades.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="fancybox-item">
                                    <div class="fancybox__icon">
                                        <i class="icon-tpms"></i>
                                    </div>
                                    <div class="fancybox__content">
                                        <h4 class="fancybox__title">TPMS</h4>
                                        <p class="fancybox__desc">Presión optima en tiempo real de llantas, evitando cualquier inconveniente durante el viaje.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="fancybox-item">
                                    <div class="fancybox__icon">
                                        <i class="icon-optimization"></i>
                                    </div>
                                    <div class="fancybox__content">
                                        <h4 class="fancybox__title">Optimización de rutas</h4>
                                        <p class="fancybox__desc">Contamos con sistemas de protección para nuestras.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-4">
                        <div class="col-sm-12 col-md-6 col-lg-12">
                            <div class="fancybox-item fancybox-item-custom">
                                <div class="fancybox__content">
                                    <h4 class="fancybox__title fancybox-title">¿Cómo podemos ayudarte?</h4>
                                    <p>Entendemos la importancia de abordar cada viaje de manera integral y creemos en el poder de la comunicación simple y fácil.</p>
                                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                        <Link to="/contact" class="btn btn__primary btn__hover3 btn__lg mt-20  btn-info">Contáctanos</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
