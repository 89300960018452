import React from 'react'

export default function AboutTeam() {
    return (
        <section id="about4" className="about about-2 about-4 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-7">
                <img src="/img/photos/about-2.jpg" alt="about img" className="img-fluid" />
            </div>
            <div className="col-sm-12 col-md-5 col-lg-5">
              <div className="row heading heading-2">
                <div className="col-sm-12 col-md-8 col-sm-12">
                    <small className="small-title">Somos un equipo</small>
                  <h2 className="heading__title">Nuestra gente es el activo más valioso</h2>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-12">
                  <p className="heading__desc mb-30">Nos comprometernos con nuestros clientes en desarrollar un sistema logístico flexible, de acuerdo a sus necesidades, manteniendo todos los procesos estructurados, respondiendo con prontitud ante eventos críticos.</p>
                  <p className="heading-desc">Actuamos bajo estos principios en la custodia de sus bienes, reportes de carga, traslado y entrega buscando siempre acrecentar nuestra confiabilidad.</p>
                  <div class="services-top-text text-left">
                    <p>Honestidad</p>
                    <p>Respeto</p>
                    <p>Tolerancia</p>
                    <p>Familia</p> 
                    <p>Responsabilidad</p> 
                    <p>equidad de género</p>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
